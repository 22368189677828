import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const boothStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 리스트 가져오기
        list: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Booths/'+params.siteid+'/'+params.hallid
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 정보 가져오기
        info: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Booth/'+params.siteid+'/'+params.boothid
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 등록하기
        save: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Booth/'+params.hallid+'/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수정하기
        edit: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/Booth/'+params.boothid+'/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 문의하기
        contact: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Booth/Contact/'+params.boothid+'/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 부스 로그
        log: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Booth/Log/'+params.boothid+'/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 부스 외 페이지 클릭 로그
        clicklog: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Log/Click/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default boothStore
import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const fileStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 파일 리스트 가져오기
        list: ({commit}, siteid) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/MediaFile/'+siteid
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 파일 정보 가져오기
        info: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/MediaFile/'+params.siteid+'/'+params.fileseq
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 파일 업로드
        save: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/MediaFile/Upload/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default fileStore
<script>
import Header from '@/components/layout/header';
import Footer from '@/components/layout/footer';
//import Topbar from '@/components/layout/topbar';


/**
 * Horizontal-layout
 */
export default {
  components: {
    Header,
    Footer,
    //Topbar
  },
  data() {
    return {};
  },
  computed: {

  },
  methods: {
  },
  mounted() {
   /*  document.body.classList.remove("auth-body-bg");
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    } */
  },
};
</script>

<template>
  <div>
  <!--   <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon"></i>
        </div>
      </div>
    </div> -->
    <!-- Begin page -->
    <div id="layout-wrapper">
     <!--  <Topbar /> -->
      <Header />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">

        <div class="">
          <div class="">
            <slot />

          </div>
          <!-- container-fluid -->
        </div>

        <!-- End Page-content -->
        <Footer />
      </div>
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
  </div>
</template>
<template>
  <div id="app">
     <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>
    </component>

  </div>
</template>

<script>
import Layout from './layouts/horizontal.vue';

export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>


<style>
#app {
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import BootstrapVue3 from 'bootstrap-vue-3'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'


/* https://bootstrap-vue.org/docs */
// CSS 파일 임포트
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "@/assets/css/style.css";

// 아이콘 https://remixicon.com/
import 'remixicon/fonts/remixicon.css'

const app = createApp(App);

app.use(BootstrapVue3)
app.use(router).use(store).use(VuePlyr, {
    plyr: {}
}).mount('#app')


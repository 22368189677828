import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const mainStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 정보 가져오기
        info: ({commit}, siteid) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/main/'+siteid
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 등록하기
        save: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/main/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수정하기
        edit: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/main/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 문의하기
        contact: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/main/Contact/'+params.siteid, params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default mainStore
import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const siteStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 리스트 가져오기
        info: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/site/'+params.siteid
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default siteStore 
<template>
   <footer  id="footer" class="footer p-5">

        <div class="footer_info"> 
            <div class="left">
                <div class="footerlogo">
                    <small>주최기관</small>
                    <h4>피부과학연구재단</h4>
                </div>
                <ul>
                    <li class="d-flex"><span class="title">대표</span><span class="black">강훈</span></li>
                    <li class="d-flex"><span class="title">사업자등록번호</span><span class="black">214-82-12834</span></li>
                    <li class="d-flex"><span class="title">주소</span><span class="black">(06647) 서울특별시 서초구 반포대로 114 SR타워 2층</span></li>
                    <li class="d-flex"><span class="title">Tel</span><span class="black">070-4639-5201</span></li>
                    <li class="d-flex"><span class="title">E-mail</span><span class="black">kda@derma.or.kr</span></li>
                </ul>
            </div>

            <div class="left">
                <div class="footerlogo">
                    <small>사무국</small>
                    <h4>허밍아이엠씨</h4>
                </div>
                <ul>
                    <li class="d-flex"><span class="title">대표</span><span class="black">노정욱</span></li>
                    <li class="d-flex"><span class="title">사업자등록번호</span><span class="black">107-87-11721</span></li>
                    <li class="d-flex"><span class="title">주소</span><span class="black">(06628) 서울특별시 서초구 강남대로 311, 9층</span></li>
                    <li class="d-flex"><span class="title">Tel</span><span class="black">02-2038-4954</span></li>
                    <li class="d-flex"><span class="title">E-mail</span><span class="black">d1m2@humming.co.kr</span></li>
                </ul>
            </div>

        </div>

        <p class="footer_copy">Copyright© Korean Dermatology Research Foundation. All Rights Reserved.</p>
    
    </footer>
</template>

import { createStore } from 'vuex';
//import i18n from '../i18n';
import axios from 'axios'
import VueCookies from 'vue-cookies';

import boothStore from '@/store/modules/boothStore.js';
import exhibitionStore from '@/store/modules/exhibitionStore.js';
import fileStore from '@/store/modules/fileStore.js';
import mainStore from '@/store/modules/mainStore.js';
import siteStore from '@/store/modules/siteStore.js';

export default new createStore({
    state: {
        siteInfo: null,
        footerInfo: null,
    },
    mutations: {
        setSiteInfo(state, data){
            state.siteInfo = data;
            VueCookies.set('onmice_siteId', data.siteId, '10min');
            VueCookies.set('onmice_name', data.name, '10min');
            VueCookies.set('onmice_manager', data.manager, '10min');
            VueCookies.set('onmice_contract', data.contract, '10min');
            VueCookies.set('onmice_isLogin', data.isLogin, '10min');
            VueCookies.set('onmice_storageCDN', data.storageCDN, '10min');
            VueCookies.set('onmice_storageZoneName', data.storageZoneName, '10min');
        },

        setFooterInfo(state, data){
            state.footerInfo = data;
            VueCookies.set('onmice_addr', data.addr);
            VueCookies.set('onmice_bgImageFileSeq', data.bgImageFileSeq);
            VueCookies.set('onmice_businessNo', data.businessNo);
            VueCookies.set('onmice_closeImageFileSeq', data.closeImageFileSeq);
            VueCookies.set('onmice_copyright', data.copyright);
            VueCookies.set('onmice_email', data.email);
            VueCookies.set('onmice_representative', data.representative);
            VueCookies.set('onmice_tel', data.tel);
            VueCookies.set('onmice_foldername', data.foldername);
        },

        resetFooterInfo(state, data){
            if(state.footerInfo == null){
                state.footerInfo = data;
            }
        },

    },
    getters: {
        siteInfo(state){
            return state.siteInfo;
        },
        footerInfo(state){
            return state.footerInfo;
        },
    },
    actions: {},
    modules: {
        boothStore: boothStore,
        exhibitionStore: exhibitionStore,
        fileStore: fileStore,
        mainStore: mainStore,
        siteStore: siteStore,
    },
});
